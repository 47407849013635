import { template as template_3cecc4bbc8a14ef7bd1b859eb861d6d5 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_3cecc4bbc8a14ef7bd1b859eb861d6d5(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
