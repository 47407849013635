import { template as template_573f74d068af41edb39f948262b301ea } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const OpLikesCell = template_573f74d068af41edb39f948262b301ea(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="op_likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default OpLikesCell;
