import { template as template_8fc7e42fbe0e40da8d949235e248d0d6 } from "@ember/template-compiler";
const WelcomeHeader = template_8fc7e42fbe0e40da8d949235e248d0d6(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
