import { template as template_cacd06d6d8d142bfb4a10fca75d95e48 } from "@ember/template-compiler";
const FKText = template_cacd06d6d8d142bfb4a10fca75d95e48(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
