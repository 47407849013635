import { template as template_d5ad4515726e45e78487e066f4d2cd2a } from "@ember/template-compiler";
const FKControlMenuContainer = template_d5ad4515726e45e78487e066f4d2cd2a(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
