import { template as template_2a601ef20d1a4ee4a7b461339a553919 } from "@ember/template-compiler";
import NotificationsTracking from "discourse/components/notifications-tracking";
const TagNotificationsTracking = template_2a601ef20d1a4ee4a7b461339a553919(`
  <NotificationsTracking
    @onChange={{@onChange}}
    @levelId={{@levelId}}
    @showCaret={{false}}
    @showFullTitle={{false}}
    @prefix="tagging.notifications"
    class="tag-notifications-tracking"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default TagNotificationsTracking;
