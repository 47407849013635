import { template as template_19321d3d848e44b8827c83f4f95add71 } from "@ember/template-compiler";
const SidebarSectionMessage = template_19321d3d848e44b8827c83f4f95add71(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
